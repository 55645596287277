import feedback from './feedback.js';
import theme from './theme.js';
import request from './request.js';
import competence from "@hihub/vue-hihub-competences/src/store/competence";

/**
 * Vuex store accumulator
 *
 * Reexport to simplest use in main.js
 */

export default [
    {
        name: "feedback",
        module: feedback
    },
    {
        name: "competence",
        module: competence
    },
    {
        name: "theme",
        module: theme
    },
    {
        name: "request",
        module: request
    },
  ];
