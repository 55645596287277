import Vue from "vue";

const feedback = {
    namespaced: true,
    state: {
        allRequests: {},
        allRequestsDispatchId: 0 
    },
    getters: {
        getAllRequest: state => () => state.allRequests,
    },
    mutations:{
        SET_ALL_REQUEST(state, {data, pagination}){
            Vue.set(state.allRequests, "data", data);
            Vue.set(state.allRequests, "pagination", pagination);
        },
    },
    actions: {
        loadAllRequest({state, commit}, data){
            let sort = [];
            if(data.sort){
              sort.push({ [data.sort.sort_column] : data.sort.sort_type});
            } else {
              sort = [
                {"created_at_table": {"order": "desc"}}
              ];
            }
            let page = data.page || 1;
            let query = data.query || "";
            let filter = data.filter || [];
            state.allRequestsDispatchId++;
            let dispatchId = state.allRequestsDispatchId;
            return this.$app.$api.feedback.admin.request({page: page, per_page: 10, search: query, sort: sort, filter: filter})
                .then((response) => {
                    if(state.allRequestsDispatchId == dispatchId){
                        let pagination = {};
                        for(let index in response.data){
                        if(index != "data"){
                            pagination[index] = response.data[index];
                        }
                        }
                        commit("SET_ALL_REQUEST", {"data": response.data.data, "pagination": pagination});
                    }
                });
        }
    }
};

export default feedback;