export default {
  menu: {
    select: "Отзыв коллеге",
    my: "Мои отзывы",
    admin: "Панель администратора",
    all_reports: "Отзывы на сотрудников",
    submit_request: "Оставить обращение",
    all_request: "Обращения по темам"
  },
  views: {
    workers_list: {
      info: "Начните вводить имя или фамилию сотрудника.",
      send: "Перейти к отзыву"
    },
    send_feedback: {
      thank: "Спасибо",
      been_sent: "Обратная связь отправлена",
      send_more: "Отправить ещё",
      feedback_about: "Оставить отзыв о",
      well_done: "Молодец",
      try_harder: "Старайся",
      write: "Напишите ваш отзыв",
      error_occured: "Произошла ошибка при заполнении полей",
      anon: "Отправить анонимно",
      anon_limit: "Лимит анонимных сообщений превышен",
      send: "Отправить отзыв",
      add_comment: "Добавьте комментарий",
      message_lenght: "Длина сообщения должна быть больше 6 символов и меньше 2000",
    },
    grid: {
      block: "Компетенция",
      change: "Изменить",
      comment: "Комментарий",
      competence: "Индикатор",
      date: "Дата и время",
      user_from: "Отправитель",
      user_to: "Получатель",
      value: "Оценка",
      visibility: "Видимость",
      theme: "Тема",
      message: "Обращение",
      locale: {
        page: "Страница",
        of: "из",
        to: "до",
        loadingOoo: "Загрузка...",
        noRowsToShow: "Нет данных",
        filterOoo: "Фильтрация...",
        applyFilter: "Применить",
        equals: "Соответсвует",
        notEqual: "Не соответсвует",
        lessThan: "Меньше чем",
        greaterThan: "Больше чем",
        lessThanOrEqual: "Меньше или равно",
        greaterThanOrEqual: "Больше или равно",
        inRange: "В диапазоне",
        contains: "Содержит",
        notContains: "Не содержит",
        startsWith: "Начинается с",
        endsWith: "Заканчивается на",
        andCondition: "И",
        orCondition: "ИЛИ"
      }
    },
    show_feedback: {
      info: "Список отзывов на",
      anon: "Аноним",
      user_deleted: "удалён",
    },
    all_reports: {
      all: "Все отзывы",
      error_status: "Произошла ошибка. Невозможно обновить статус.",
    },
    all_request: {
      title: "Обращения по темам",
      theme: "Тема",
      author: "Отправитель",
      request: "Обращение",
    },
    table: {
      filter: "Нажмите чтобы использовать фильтр",
      user_from: "Отправитель",
      user_to: "Получатель",
      competence: "Компетенция",
      reset: "Сбросить",
      search: "Поиск",
      gradedLang: "Получатель",
      gradingLang: "Отправитель",
      typeCompetenceLang: "Тип компетенции",
      competenceLang: "Компетенция",
      indicatorLang: "Индикатор",
      theme_request: "Тема обращения",
      theme: "Тема",
    },
    theme_request: {
      title: "Оставить обращение",
      button_title: "Создать тему для обращений"
    },
    create_theme: {
      title: "Создание темы обращения",
      name_title: "Название",
      warning_name_title: "Название должно содержать от 3 до 255 символов",
      recipients_title: "Получатели сообщений",
      warning_recipients_title: "Нужно указать хотя бы одного пользователя",
      descritpion_title: "Описание темы обращения",
      warning_description_title: "Описание должо содержать от 10 до 1000 символов",
      toster_warning_not_save: "Не удалось сохранить тему",
      toster_warning_not_update: "Не удалось обновить тему",
      toster_warning_not_delete: "Не удалось удалить тему",
      modal_delete_title: "Удаление темы",
      modal_delete_message: "Вы действительно хотите удалить тему ",
    },
    send_request: {
      title_text: "Опишите проблему или задайте свой вопрос",
      restriction_text: "Ограничение от 10 до 5000 символов",
      toster_warning_not_seng: "Не удалось отправить обращение"
    },
    button: {
      cancel: "Отменить",
      send: "Отправить",
      save: "Опубликовать",
      delete: "Удалить",
      edit: "Редактировать"
    },


    //old
    sync: {
      sync: "Синхронизация",
      upd: "Обновлений",
      finish: "Обновления завершены"
    },
    form: {
      add: "Дополните оценку",
      select_competence: "Выберите индикатор",
      select_partition: "Выберите компетенцию",
      select_block: "Выберите блок компетенций",
      to_the_list: "К списку",
    },
    report: {
      department: "Список отзывов на сотрудников отделов",
      error_excel: "Ошибка при создании Excel",
      load_to_excel: "Выгрузить в Excel",
      your_excel: "Ваш Excel"
    },
    limits: {
      day: "День",
      fail_response: "Произошла ошибка. Невозможно получить значение ограничений из базы.",
      month: "Месяц",
      new_gap: "Вы установили новый промежуток!",
      set_gap: "Выберите промежуток времени",
      set_number: "Установите количество допустимых анонимных сообщений",
      title: "Установка лимита отправки анонимных сообщений",
      week: "Неделя"
    }
  },

};
