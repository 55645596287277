export default class ApiRequests {}

ApiRequests.feedback = {
    store: ({ client }, data) => client.post('/api/feedback/store', data),
    notify: ({ client }, data) => client.post('/api/feedback/notify', data),
    limit: ({ client }, user_id) => client.get('/api/feedback/limit/' + user_id),
    show: ({ client }, data) => client.post('/api/feedback/show', data),
    admin: {
        report: ({ client }, data) => client.post('/api/feedback/admin/report', data),
        request: ({ client }, data) => client.post('/api/feedback/admin/request', data),
        toggleActiveStatus: ({ client }, id) => client.post('/api/feedback/admin/status/' + id),
        getExcel: ({ client }, data) => client.post('/api/feedback/admin/excel/', data),
        getInterval: ({ client }) => client.get('/api/feedback/admin/interval'),
        setInterval: ({ client }, data) => client.post('/api/feedback/admin/interval', data)
    },
    getCompetencesAndPartitions: ({ client }, user_id) => client.get( '/api/feedback/partitions-and-competences/user/' + user_id),
    loadCompetence: ({ client }, data) => client.get('/api/feedback/load-competence/' + data),
    loadIndicator: ({ client }, data) => client.get('/api/feedback/load-indicator/' + data),

    getThemeList: ({ client }) => client.get('/api/feedback/load-theme-list/'),
    getTheme: ({ client }, id) => client.get('/api/feedback/load-theme/' + id),
    createTheme: ({ client }, data) => client.post('/api/feedback/create-theme/', data),
    updateTheme: ({ client }, data) => client.post('/api/feedback/update-theme/' + data.id, data),
    deleteTheme: ({ client }, id) => client.get('/api/feedback/delete-theme/' + id),
    createRequest: ({ client }, data) => client.post('/api/feedback/create-request/' + data.id, data),
};


