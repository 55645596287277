import Vue from 'vue';

const theme = {
  state: {
    themes: [],
  },
  getters: {
    allThemes: state => () => state.themes,
      
    themeById: (state) => (id) => {
      return state.themes.find(theme => theme.id == id);
    },
  },
  mutations: {
    updateTheme(state, { id, updatedTheme }) {
      const index = state.themes.findIndex(theme => theme.id === id);
      if (index !== -1) {
        Vue.set(state.themes, index, { ...state.themes[index], ...updatedTheme });
      }
    },
    setThemes(state, themes) {
      state.themes = themes;
    },
    addTheme(state, newTheme) {
      state.themes.push(newTheme);
    },
  },
  actions: {
    updateTheme({ commit }, payload) {
      commit('updateTheme', payload);
    },
    setThemes({ commit }, themes) {
      commit('setThemes', themes);
    },
    loadThemesFromApi({ commit }) {
      return new Promise((resolve, reject) => {
        this.$app.$api.feedback.getThemeList({})
          .then(response => {
            const themes = response.data.data; 
            commit('setThemes', themes);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addTheme({ commit }, newTheme) {
      commit('addTheme', newTheme);
    },
  },
};

export default theme;