export default {
  menu: {
    select: "Feedback an einen Kollegen",
    my: "Meine Bewertungen",
    admin: "Administrationsmenü",
    all_reports: "Mitarbeiterfeedback",
    submit_request: "Eine Anfrage einreichen",
    all_request: "Anfragen zu Themen"
  },
  views: {
    workers_list: {
      info: "Beginnen Sie mit der Eingabe des Vor- oder Nachnamens des Mitarbeiters.",
      send: "Schreiben Sie eine Rezension"
    },
    send_feedback: {
      thank: "Danke!",
      been_sent: "Feedback gesendet",
      send_more: "Schicke mehr",
      feedback_about: "Hinterlassen Sie Feedback zu",
      well_done: "Gut gemacht!",
      try_harder: "Gib dein Bestes",
      write: "Schreiben Sie eine Bewertung",
      error_occured: "Beim Ausfüllen der Felder ist ein Fehler aufgetreten",
      anon: "Anonym senden",
      anon_limit: "Das Limit für anonyme Nachrichten wurde überschritten",
      send: "Schreiben Sie eine Rezension",
      add_comment: "Fügen Sie bitte einen Kommentar hinzu",
      message_length: "Die Nachrichtenlänge muss mehr als 6 Zeichen und weniger als 2000 betragen"
    },
    grid: {
      block: "Kompetenz",
      change: "Ändern",
      comment: "Kommentar",
      competence: "Indikator",
      date: "Datum und Uhrzeit",
      user_from: "Absender",
      user_to: "Empfängerin",
      value: "Wert",
      visibility: "Sichtweite",
      theme: "Thema",
      message: "Anfrage",
      locale: {
        page: "Seite",
        of: "von",
        to: "zu",
        loadingOoo: "Wird geladen...",
        noRowsToShow: "Keine Zeilen zum Anzeigen",
        filterOoo: "Filtern...",
        applyFilter: "Anwenden",
        equals: "Gleich",
        notEqual: "Nicht gleich",
        lessThan: "Weniger als",
        greaterThan: "Größer als",
        lessThanOrEqual: "Weniger als oder gleich",
        greaterThanOrEqual: "Größer als oder gleich",
        inRange: "Im Bereich",
        contains: "Enthält",
        notContains: "Nicht enthalten",
        startsWith: "Beginnt mit",
        endsWith: "Endet mit",
        andCondition: "UND",
        orCondition: "ODER"
      }
    },
    show_feedback: {
      info: "Liste der Rückmeldungen zu",
      anon: "Anonym",
      user_deleted: "gelöscht",
    },
    all_reports: {
      all: "Alle Rückmeldungen",
      error_status: "Ein Fehler ist aufgetreten. Es ist nicht möglich, den Status zu aktualisieren.",
    },
    all_request: {
      title: "Anfragen zu Themen",
      theme: "Thema",
      author: "Absender",
      request: "Anfrage",
  },
    table: {
      filter: "Klicken Sie, um den Filter zu verwenden",
      user_from: "Absender",
      user_to: "Empfänger",
      competence: "Kompetenz",
      reset: "Zurücksetzen",
      search: "Suchen",
      gradedLang: "Absender",
      gradingLang: "Empfänger",
      typeCompetenceLang: "Kompetenztyp",
      competenceLang: "Kompetenz",
      indicatorLang: "Indikator",
      theme_request: "Thema der Anfrage",
      theme: "Thema",
    },
    theme_request: {
      title: "Anfrage hinterlassen",
      button_title: "Thema für Anfragen erstellen"
    },
    create_theme: {
      title: "Erstellung eines Anfragethemas",
      name_title: "Titel",
      warning_name_title: "Der Titel muss zwischen 3 und 255 Zeichen enthalten",
      recipients_title: "Nachrichtenempfänger",
      warning_recipients_title: "Mindestens einen Benutzer angeben",
      descritpion_title: "Beschreibung des Anfragethemas",
      warning_description_title: "Die Beschreibung muss zwischen 10 und 1000 Zeichen enthalten",
      toster_warning_not_save: "Das Thema konnte nicht gespeichert werden",
      toster_warning_not_update: "Das Thema konnte nicht aktualisiert werden",
      toster_warning_not_delete: "Das Thema konnte nicht gelöscht werden",
      modal_delete_title: "Thema löschen",
      modal_delete_message: "Möchten Sie das Thema wirklich löschen "
  },
  send_request: {
      title_text: "Beschreiben Sie das Problem oder stellen Sie Ihre Frage",
      restriction_text: "Einschränkung von 10 bis 5000 Zeichen",
      toster_warning_not_send: "Die Anfrage konnte nicht gesendet werden"
  },
  button: {
      cancel: "Abbrechen",
      send: "Senden",
      save: "Veröffentlichen",
      delete: "Löschen",
      edit: "Bearbeiten"
  },
  }
};
