/**
 * Aside menu config
 *
 * To more info about config of menu items go to knowledgebase, lms, review (in review you can see menu for Head of department)
 */
import store from "@/core/services/store";
import userHasAnyRole from '@hihub/vue-hihub-ui-core/src/core/services/helpers/UserHasAnyRole';

export default {
    submenu: {
        list: {
            router_link: 'WorkersList',
            b_icon: "chat-right-text",
            text_code: 'menu.select',
            text: 'WorkersList',
        },
        my: {
            router: {
                name: 'ShowFeedback',
                params: {id: 0}
            },
            b_icon: "journal-medical",
            text_code: 'menu.my',
            text: 'ShowFeedback',
        },
        request: {
            router: {
                name: 'ThemeRequest',
            },
            b_icon: "person-square",
            text_code: 'menu.submit_request',
            text: 'Request',
        },
        admin: {
            router_link: 'AllReports',
            b_icon: "shield-check",
            text: 'GoalSubordinates',
            text_code: 'menu.admin',
            active: function() {
                const user = store.getters.currentUser;
                return user ? userHasAnyRole(['admin', 'admin_feedback'], user.role_list) : false;
            },
            menu: {
                all: {
                    router_link: 'AllReports',
                    b_icon: "person-lines-fill",
                    text_code: 'menu.all_reports',
                    text: 'AllReports',
                },
                /*limit: {
                    router_link: 'AnonymLimit',
                    icon: "flaticon2-plus",
                    text_code: 'feedback.menu.limits',
                    text: 'AnonymLimit',
                }*/
                allRequest: {
                    router_link: 'AllRequest',
                    b_icon: 'collection',
                    text_code: 'menu.all_request',
                    text: 'AllRequest',
                },
            }
        }
    }
};
